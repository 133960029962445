import svgBus from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/bus.svg"
import svgCar from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/car.svg"
import svgCarCrash from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/car-burst.svg"
import svgFighterJet from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/jet-fighter.svg"
import svgHelicopter from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/helicopter.svg"
import svgPlane from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/plane.svg"
import svgRocket from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/rocket.svg"
import svgSnowplow from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/snowplow.svg"
import svgTractor from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/tractor.svg"
import svgTruck from "url:../node_modules/@fortawesome/fontawesome-free/svgs/solid/truck.svg"
import Collapse from "bootstrap/js/src/collapse"
import Modal from "bootstrap/js/src/modal"
import animateScrollTo from "animated-scroll-to"
import { Rect, loadSVGFromURL, util, Canvas } from "fabric"
//import Modal from "../node_modules/bootstrap/js/src/modal.js";

var $ = id => document.getElementById(id)
var _ = s => document.querySelectorAll(s)

function postKontaktForm() {
	var form = $("rkt-kontakt-skjema")
	if (!form.reportValidity()) {
		return false
	}
	var post = {
		kontaktTekst: $("kontaktTekst").value
	}
	_("#rkt-kontakt-skjema input").forEach(el => (post[el.id] = el.value))

	// hide thingy, show spinner, wait for response, show response
	var swoosh = $("kontakt-container")
	var toShow = $("kontakt-feedback")

	var swooshListener = function (ev) {
		ev.stopPropagation()
		swoosh.classList.remove("animate__animated", "animate__fadeOut")
		swoosh.classList.add("invisible")
		toShow.classList.remove("d-none")
		form.reset()
		toShow.classList.add("animate__animated", "animate__fadeIn")
	}

	swoosh.addEventListener("animationend", swooshListener, { once: true })
	swoosh.classList.add("animate__animated", "animate__fadeOut")

	var resLabel = $("kontakt-resultat")

	resLabel.textContent = "Vennligst vent ..."

	fetch("/kontakt.php", {
		method: "POST",
		mode: "same-origin",
		cache: "no-cache",
		credentials: "same-origin",
		headers: { "Content-Type": "application/json" },
		redirect: "follow",
		referrerPolicy: "strict-origin",
		body: JSON.stringify(post)
	})
		.then(res => {
			if (!res.ok || (res.status >= 400 && res.status < 600)) {
				throw new Error("En uventet feil har oppstått. Meldingen din har ikke blitt sendt.")
			}
			return res.json()
		})
		.then(data => {
			resLabel.textContent = data.msg
			if (data.status == 100) {
				var alertBox = $("kontakt-resultat-boks")
				alertBox.classList.remove("alert-danger")
				alertBox.classList.add("alert-primary")
			}
		})
		.catch(error => (resLabel.textContent = error.message))
}

function prepareKontakt() {
	var icons = [
		{ id: "fa-solid:bus-alt", svg: svgBus },
		{ id: "fa-solid:car", svg: svgCar },
		{ id: "fa-solid:car-crash", svg: svgCarCrash },
		{ id: "fa-solid:fighter-jet", svg: svgFighterJet },
		{ id: "fa-solid:helicopter", svg: svgHelicopter },
		{ id: "fa-solid:plane", svg: svgPlane },
		{ id: "fa-solid:rocket", svg: svgRocket },
		{ id: "fa-solid:snowplow", svg: svgSnowplow },
		{ id: "fa-solid:tractor", svg: svgTractor },
		{ id: "fa-solid:truck", svg: svgTruck }
	]

	var canvas = (window.__canvas = new Canvas("captcha", {
		selection: false,
		renderOnAddRemove: false
	}))
	canvas.setDimensions({
		width: 350,
		height: 180
	})

	var maxHeight = canvas.getHeight()
	var maxWidth = canvas.getWidth()

	canvas.svgViewportTransformation = false

	var queue = []
	function runQueue() {
		var n = queue.shift()
		if (n === undefined) {
			canvas.requestRenderAll()
			queue = null
		} else {
			n()
		}
	}

	icons.forEach(icon => {
		queue.push(() => {
			loadSVGFromURL(icon.svg, (_, svg) => {
				var size = util.getRandomInt(24, 48)
				svg.scaleToWidth(size)
				svg.set({
					left: util.getRandomInt(size / 2, maxWidth - size),
					top: util.getRandomInt(size / 2, maxHeight - size),
					id: icon.id,
					origSize: size,
					lockRotation: true,
					lockScalingX: true,
					lockScalingY: true,
					hasControls: false
				})
				svg.setCoords()
				canvas.add(svg)

				runQueue()
			})
		})
	})

	var center

	queue.push(() => {
		center = new Rect()
		center.set({
			id: "center",
			origSize: 1,
			lockRotation: true,
			lockScalingX: true,
			lockScalingY: true,
			hasControls: false,
			selectable: false,
			evented: false
		})
		center.width = 1
		center.height = 1
		canvas.add(center)
		canvas.centerObject(center)
		runQueue()
	})

	queue.push(() => {
		var max = canvas.size()
		for (var i = 0; i < max; i++) {
			var obj = canvas.item(i)
			if (obj.type != "rect") {
				var size = obj.getScaledWidth()
				var tries = 100
				var loop = false
				do {
					for (var j = 0; j < max; j++) {
						if (j != i) {
							var onTop = canvas.item(j)
							if (obj.intersectsWithObject(onTop, true, true)) {
								obj.set({
									left: util.getRandomInt(size / 2, maxWidth - size),
									top: util.getRandomInt(size / 2, maxHeight - size)
								})
								obj.setCoords()
								loop = true
								break
							}
						}
					}
					tries--
					if (tries < 1) {
						loop = false
					}
				} while (loop == true)
			}
		}

		canvas.renderAll()
		runQueue()
	})

	queue.push(() => {
		$("rkt-kontakt-send").onclick = postKontaktForm // XXX null onclick
		$("_captcha").value = JSON.stringify(canvas.toObject(["id", "origSize"]))
		runQueue()
	})

	function onChange(opt) {
		if (opt.action === "drag") {
			var max = canvas.size()
			for (var i = 0; i < max; i++) {
				var obj = canvas.item(i)
				if (obj.type != "rect") {
					obj.set("centered", center.isContainedWithinObject(obj))
				}
			}
			$("_captcha").value = JSON.stringify(canvas.toObject(["id", "origSize", "centered"]))
		}
	}
	canvas.on({
		"object:modified": onChange
	})

	runQueue()
}

const select = (el, all = false) => {
	el = el.trim()
	if (all) {
		return [...document.querySelectorAll(el)]
	} else {
		return document.querySelector(el)
	}
}

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
	let selectEl = select(el, all)
	if (selectEl) {
		if (all) {
			selectEl.forEach(e => e.addEventListener(type, listener))
		} else {
			selectEl.addEventListener(type, listener)
		}
	}
}

/**
 * Easy on scroll event listener
 */
const onscroll = (el, listener) => {
	el.addEventListener("scroll", listener)
}

function initRKT() {
	// $("scrollToContent").onclick = function () {
	// 	animateScrollTo($("rkt-om-oss"), {
	// 		verticalOffset: -90,
	// 		maxDuration: 1200,
	// 		minDuration: 0
	// 	})
	// }

	_(".needs-validation").forEach(form => {
		form.addEventListener(
			"submit",
			function (event) {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}
				form.classList.add("was-validated")
			},
			false
		)
	})

	var hiddenCaptcha = $("captchaContainer")
	var captchaCollapser = new Collapse(hiddenCaptcha, { toggle: false })
	$("invalidCheck").oninput = function () {
		// XXX once
		captchaCollapser.show()
		return true
	}

	prepareKontakt()

	// let selectHeader = select("#header")
	// if (selectHeader) {
	// 	let headerOffset = selectHeader.offsetTop
	// 	let nextElement = selectHeader.nextElementSibling
	// 	const headerFixed = () => {
	// 		if (headerOffset - window.scrollY <= 0) {
	// 			selectHeader.classList.add("fixed-top")
	// 			nextElement.classList.add("scrolled-offset")
	// 		} else {
	// 			selectHeader.classList.remove("fixed-top")
	// 			nextElement.classList.remove("scrolled-offset")
	// 		}
	// 	}
	// 	window.addEventListener("load", headerFixed)
	// 	onscroll(document, headerFixed)
	// }

	if (window.location.href.indexOf("#personvern") != -1) {
		Modal.getOrCreateInstance($("personvern")).show()
	} else if (window.location.href.indexOf("#diesel") != -1) {
		Modal.getOrCreateInstance($("diesel")).show()
	} else if (window.location.href.indexOf("#stilling") != -1) {
		Modal.getOrCreateInstance($("stilling")).show()
	}
}

if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", initRKT)
} else {
	initRKT()
}
